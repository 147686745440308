import {
  ApplicationVerifier,
  ConfirmationResult,
  User,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  signInWithPhoneNumber,
  updateProfile,
} from "firebase/auth";

import React, { useContext, useEffect, useState } from "react";
import { firebaseAuth } from "../../firebase/firebase";
import { AuthModal } from "./AuthModal";
import { isUserInDB } from "./utils/isUserInDB";

interface AuthContextValues {
  isAuth: boolean;
  isAuthenticating: boolean;
  currentUser: User | null;
  signInWithPhone: (
    phoneNumber: string,
    appVerifier: ApplicationVerifier,
  ) => Promise<ConfirmationResult>;
  signOut: () => Promise<void>;
  updateInformation: (name: string) => void;
  showSignInModal: () => void;
  closeModal: () => void;
  checkIfUserExists: () => void;
}

const AuthContext = React.createContext<AuthContextValues | undefined>(
  undefined,
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = firebaseAuth;
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [showDialog, setShowDialog] = useState(false);

  function signInWithPhone(
    phoneNumber: string,
    appVerifier: ApplicationVerifier,
  ): Promise<ConfirmationResult> {
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
  }
  function signOut(): Promise<void> {
    return firebaseSignOut(auth);
  }
  function updateInformation(name: string) {
    if (currentUser) {
      updateProfile(currentUser, { displayName: name });
    }
  }
  function showSignInModal(): void {
    setShowDialog(true);
  }
  function closeModal(): void {
    setShowDialog(false);
  }
  function checkIfUserExists(): void {
    !currentUser ? showSignInModal() : isUserInDB(currentUser.uid);
    return;
  }

  useEffect(() => {
    setIsAuthenticating(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsAuthenticating(false);
    });
    return () => unsubscribe();
  }, [auth]);

  const values: AuthContextValues = {
    isAuth: !!currentUser?.phoneNumber,
    isAuthenticating,
    currentUser,
    signInWithPhone,
    signOut,
    updateInformation,
    showSignInModal,
    closeModal,
    checkIfUserExists,
  };

  return (
    <AuthContext.Provider value={values}>
      {children}
      <AuthModal showDialog={showDialog} />
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValues => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
