import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../../firebase/firebase";
import { DB, RestaurantBasicInfo } from "../../models/firebase.models";
import { UserRole } from "../../models/user.models";

interface Props {
  id: string | undefined;
}

export const useGetRestaurantById = ({ id }: Props) => {
  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState<RestaurantBasicInfo | null>(
    null,
  );

  useEffect(() => {
    setLoading(true);
    if (!id) {
      setRestaurant(null);
      setLoading(false);
      return;
    }

    // Define the reference for the restaurant
    const restaurantRef = doc(firestore, id, DB.CONFIGURATION);

    // Listen for real-time updates
    const unsubscribe = onSnapshot(restaurantRef, async (snapshot) => {
      try {
        if (snapshot.exists()) {
          const restaurantData = snapshot.data();
          const restaurantDetails: RestaurantBasicInfo = {
            id: id,
            name: restaurantData?.name || "Unknown",
            role: restaurantData?.role || UserRole.OWNER,
          };

          setRestaurant(restaurantDetails);
        } else {
          setRestaurant(null);
        }

        setLoading(false);
      } catch (e) {
        console.error("Error fetching restaurant:", e);
        setLoading(false);
      }
    });

    // Cleanup: unsubscribe when the component is unmounted
    return () => unsubscribe();
  }, [id]);

  return { restaurant, loading };
};
