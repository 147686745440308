import { Alert } from "antd";

interface Props {
  message: string;
  className?: string;
}

export const ErrorMessage: React.FC<Props> = ({ message, className }) => {
  return (
    <div
      style={{ height: "100vh" }}
      className={`flex items-center justify-center ${className ?? ""}`}
    >
      <Alert message={message} type="error" />
    </div>
  );
};
