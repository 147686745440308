export interface Restaurant {
  id: string;
  role: UserRole;
}

export enum UserRole {
  OWNER = "owner",
  MANAGER = "manager",
  WAITER = "waiter",
  CHEF = "chef",
  DRIVER = "driver",
}
