import { Button, Form, FormInstance, FormListFieldData, Select } from "antd";
import React from "react";
import { Configuration, SelectOption } from "../../../../models/main.model";
import { MenuFormValues } from "../../../../models/menuConfiguration.models";
import { AddAndRemoveButtons } from "./AddAndRemoveButtons";
import { AddDetails } from "./AddDetails";

interface Props {
  fields: FormListFieldData[];
  add: Function;
  remove: Function;
  form: FormInstance<MenuFormValues>;
  categoryOptions: SelectOption[];
  restaurantId: string;
  configuration: Configuration;
}

export const CategoryItem: React.FC<Props> = ({
  fields,
  add,
  remove,
  form,
  categoryOptions,
  restaurantId,
  configuration,
}) => {
  const addCategory = () => {
    add({ details: [{}] });
  };

  return (
    <>
      {fields.map(({ key, name, ...restField }) => (
        <div key={key}>
          <Form.Item
            {...restField}
            name={[name, "category"]}
            rules={[{ required: true, message: "Wybierz dostępną kategorię" }]}
            className="my-2"
          >
            <Select
              labelInValue
              placeholder="Wybierz kategorię"
              style={{ width: "100%" }}
            >
              {categoryOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name={[name, "details"]}>
            {(
              fields: FormListFieldData[],
              { add: addItem, remove: removeItem },
            ) => (
              <>
                <AddDetails
                  field={fields}
                  configuration={configuration}
                  form={form}
                  restaurantId={restaurantId}
                  categoryId={name}
                />
                <AddAndRemoveButtons
                  add={addItem}
                  remove={removeItem}
                  name={fields.length - 1}
                />
              </>
            )}
          </Form.List>
        </div>
      ))}

      <div className="w-1/2 mx-auto">
        {fields.length > 0 ? (
          <Button
            type="dashed"
            onClick={() => remove(fields.length - 1)}
            className="my-2"
            block
          >
            Usuń kategorię
          </Button>
        ) : null}

        <Button
          onClick={addCategory}
          type="primary"
          className="bg-blue-500"
          block
        >
          Dodaj kategorię
        </Button>
      </div>
    </>
  );
};
