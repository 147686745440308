import { FirestoreErrorCode } from "@firebase/firestore";

type CustomFirestoreErrorCode =
  | FirestoreErrorCode
  | "new-order-created"
  | "order-creation-failed"
  | "snapshot-error"
  | "missing-details";

export interface CustomFirestoreError {
  name: string;
  code: CustomFirestoreErrorCode;
  message: string;
  stack?: string;
}

export interface Configuration {
  name: string;
  backgroundColor: string;
  mainColor: string;
  textColor: string;
  currency: string;
  languages: string[];
}

export interface MenuItem {
  id: string;
  name: string;
  description: string;
  price: number;
  image?: string;
}

export enum MenuCategory {
  drinks = "DRINKS",
  salads = "SALADS",
  desserts = "DESSERTS",
  soups = "SOUPS",
  main_dishes = "MAIN_DISHES",
}

export enum OrderStatus {
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
}

export interface OrderItem {
  menuItemId: string;
  quantity: number;
  category: MenuCategory;
  details: MenuItem;
  // note: string; -  Invisible to the client, entered by the waiter, seen by the chef.
}

export interface Order {
  items: OrderItem[] | [];
  table: number;
  status: OrderStatus;
  orderStartTime: Date;
}

export enum Currency {
  PLN = "PLN",
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
}

export interface SelectOption {
  label: string;
  value: string;
}

export type MenuMap = {
  [categoryId: string]: {
    [language: string]: MenuItem[] | undefined;
  };
};
