import { Button } from "antd";

interface Props {
  name: number;
  remove: (index: number | number[]) => void;
  add: () => void;
}

export const AddAndRemoveButtons: React.FC<Props> = ({ add, remove, name }) => {
  return (
    <div className="mb-0 flex justify-end">
      <Button
        type="default"
        className="rounded-l-full"
        onClick={() => remove(name)}
      >
        Usuń danie
      </Button>
      <Button
        type="primary"
        className="bg-blue-500 rounded-r-full"
        onClick={add}
      >
        Dodaj danie
      </Button>
    </div>
  );
};
