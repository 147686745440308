import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { DB, NOTIFICATION_STATUS } from "../../../models/firebase.models";

export async function isUserInDB(userId: string) {
  const userRef = doc(firestore, DB.APP, DB.DATA, DB.USERS, userId);

  try {
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      // TODO: Check if there are any messages that should go into notifications
      // Here you can add code to handle this case

      return;
    }

    await setDoc(userRef, {
      restaurants: [],
    });

    // Welcome message
    const notificationsCollection = collection(userRef, DB.NOTIFICATIONS);
    const notificationId = "WELCOME_NOTIFICATION";
    const notificationDocRef = doc(notificationsCollection, notificationId);

    await setDoc(notificationDocRef, {
      time: new Date(),
      title: "NOTIFICATIONS.WELCOME_TITLE",
      message: "NOTIFICATIONS.WELCOME_MESSAGE",
      status: NOTIFICATION_STATUS.NEW,
    });
  } catch (error) {
    console.error("An error occurred while checking user in DB:", error);
    throw error;
  }
}
