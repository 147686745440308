import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loadingbar } from "../../components/Loadingbar";
import { Configuration } from "../models/main.model";
import { AppRoutes } from "./routing.model";
import { PrivateRouteWrapper } from "./wrappers/PrivateRouteWrapper";
import { StaffMenuConfigurationWrapper } from "./wrappers/StaffMenuConfigurationWrapper";

// TODO: export lazy loading from Routing.tsx to different file
const StaffHome = React.lazy(() => import("../Staff/Home/StaffHome"));
const LandingPage = React.lazy(() =>
  import("../LandingPage/LandingPage").then((module) => {
    return { default: module.LandingPage };
  }),
);
const MenuWrapper = React.lazy(() =>
  import("./wrappers/MenuWrapper").then((module) => {
    return { default: module.MenuWrapper };
  }),
);
const HomeWrapper = React.lazy(() =>
  import("./wrappers/HomeWrapper").then((module) => {
    return { default: module.HomeWrapper };
  }),
);

export const Routing: React.FC<{
  configuration: Configuration | undefined;
  logoUrl: string | undefined;
}> = ({ configuration, logoUrl }) => {
  return (
    <React.Suspense fallback={<Loadingbar />}>
      <Routes>
        <Route path={AppRoutes.LANDING_PAGE} element={<LandingPage />} />
        <Route
          path={AppRoutes.CHECKOUT_HOME}
          element={
            <HomeWrapper configuration={configuration} logoUrl={logoUrl} />
          }
        />
        <Route
          path={AppRoutes.CHECKOUT_MENU}
          element={<MenuWrapper configuration={configuration} />}
        />

        <Route path={AppRoutes.STAFF_HOME} element={<StaffHome />} />

        <Route
          path={AppRoutes.STAFF_CONSOLE_MENU}
          element={
            <PrivateRouteWrapper
              element={
                <StaffMenuConfigurationWrapper configuration={configuration} />
              }
            />
          }
        />

        {/* <Route
        path={AppRoutes.STAFF_HOME}
        element={<StaffHomeWrapper configuration={configuration} />}
      /> */}

        <Route path="*" element={<Navigate to={AppRoutes.LANDING_PAGE} />} />
      </Routes>
    </React.Suspense>
  );
};

// const StaffHomeWrapper: React.FC<{ configuration: any }> = ({
//   configuration,
// }) => {
//   const { id } = useParams<{ id: string }>();
//   return <Login id={id ?? ""} configuration={configuration} />;
// };
