import { PlusOutlined } from "@ant-design/icons";
import {
  Card,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Upload,
} from "antd";
import { RcFile } from "antd/es/upload";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../../models/main.model";
import { MenuFormValues } from "../../../../models/menuConfiguration.models";
import { fetchInitialImageUrlsFromFirebase } from "../utils/fetchInitialImageUrlsFromFirebase";
import { uploadImageToFirebase } from "../utils/uploadImageToFirebase";

interface Props {
  field: FormListFieldData[];
  configuration: Configuration;
  form: FormInstance<MenuFormValues>;
  restaurantId: string;
  categoryId: number;
}

export const AddDetails: React.FC<Props> = ({
  field,
  configuration,
  form,
  restaurantId,
  categoryId,
}) => {
  const { t } = useTranslation();
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  useEffect(() => {
    (async () => {
      const initialValues = form.getFieldsValue();
      let newImageUrls: Record<string, string> = {};

      if (initialValues?.categories?.length) {
        for (const category of initialValues.categories) {
          for (const detail of category.details) {
            if (detail.image) {
              newImageUrls[detail.image] = detail.image;
            }
          }
        }

        newImageUrls = await fetchInitialImageUrlsFromFirebase(
          restaurantId,
          newImageUrls,
        );
        setImageUrls(newImageUrls);
      }
    })();
  }, [form, restaurantId]);

  const handleUpload = async (
    file: RcFile,
    categoryId: number,
    detailIndex: number,
  ) => {
    // TODO: Implement a mechanism to prevent orphaned images in storage.
    // If an image is uploaded but the corresponding form values are not saved to the database, the image becomes orphaned.

    uploadImageToFirebase(file, restaurantId, (downloadURL, imageName) => {
      setImageUrls((prev) => ({ ...prev, [imageName]: downloadURL }));

      const curr = form.getFieldsValue();
      const targetDetail =
        curr.categories?.[categoryId]?.details?.[detailIndex];

      if (targetDetail) {
        targetDetail.image = imageName;
        form.setFieldsValue(curr);
      }
    });
  };

  return (
    <>
      {field.map(({ key, name, ...restField }) => {
        const currentImageUrl = form.getFieldValue([
          "categories",
          categoryId,
          "details",
          name,
          "image",
        ]);
        return (
          <div key={key}>
            <Card
              title={
                <div className="flex items-center">
                  <div className="flex flex-col">
                    <div className="opacity-50 mt-2">Nazwa:</div>
                    {/* hidden ID */}
                    <Form.Item
                      {...restField}
                      name={[name, "id"]}
                      initialValue={key}
                      hidden
                    >
                      <Input />
                    </Form.Item>

                    {configuration.languages.map((language) => {
                      const nameKey = `name${language.toUpperCase()}`;

                      return (
                        <Form.Item
                          {...restField}
                          key={language}
                          name={[name, nameKey]}
                          rules={[
                            { required: true, message: "Wprowadź nazwę" },
                          ]}
                          className="my-3 w-60"
                        >
                          <Input
                            placeholder={t(
                              "MENU_CONFIGURATION.PLACEHOLDERS.NAME",
                              {
                                lng: language,
                              },
                            )}
                          />
                        </Form.Item>
                      );
                    })}
                  </div>

                  <div className="ml-auto my-2">
                    <Upload
                      {...restField}
                      name={"image"}
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={({ file }) => {
                        handleUpload(file as RcFile, categoryId, name);
                      }}
                    >
                      {imageUrls[currentImageUrl] ? (
                        <img
                          src={imageUrls[currentImageUrl]}
                          alt="img"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <PlusOutlined />
                      )}
                    </Upload>
                  </div>
                </div>
              }
              className="ml-10 my-2"
            >
              <div className="flex flex-col">
                <div className="opacity-50 py-2">Cena:</div>
                <Form.Item
                  {...field}
                  name={[name, "price"]}
                  rules={[{ required: true, message: "Wprowadź cenę" }]}
                >
                  <InputNumber
                    placeholder="Cena"
                    min={0}
                    addonAfter={configuration.currency}
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col">
                <div className="opacity-50 my-2">Opis:</div>
                {configuration.languages.map((language) => {
                  const nameKey = `description${language.toUpperCase()}`;
                  return (
                    <Form.Item
                      {...restField}
                      key={language}
                      name={[name, nameKey]}
                      rules={[{ required: true, message: "Wprowadź opis" }]}
                    >
                      <Input
                        placeholder={t(
                          "MENU_CONFIGURATION.PLACEHOLDERS.DESCRIPTION",
                          {
                            lng: language,
                          },
                        )}
                      />
                    </Form.Item>
                  );
                })}
              </div>
            </Card>
          </div>
        );
      })}
    </>
  );
};
