import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../Staff/Auth/AuthContext";
import { AppRoutes } from "../routing.model";

interface PrivateRouteProps {
  element: React.ReactElement;
}

export const PrivateRouteWrapper: React.FC<PrivateRouteProps> = ({
  element,
}) => {
  const { isAuth, isAuthenticating } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticating && !isAuth) {
      navigate(AppRoutes.LANDING_PAGE, { state: { from: location } });
    }
  }, [isAuth, isAuthenticating, navigate, location]);

  return isAuthenticating ? null : element;
};
