import { Button, Form } from "antd";
import { FormInstance } from "antd/es/form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../../Routing/routing.model";
import {
  Configuration,
  MenuCategory,
  SelectOption,
} from "../../../../models/main.model";
import { MenuFormValues } from "../../../../models/menuConfiguration.models";
import { useCategoryOptions } from "../utils/useCategoryOptions.hook";
import { CategoryItem } from "./CategoryItem";

interface Props {
  form: FormInstance<MenuFormValues>;
  handleSubmit: (values: MenuFormValues) => void;
  restaurantId: string;
  configuration: Configuration;
}

export const MenuForm: React.FC<Props> = ({
  form,
  handleSubmit,
  restaurantId,
  configuration,
}) => {
  const { t } = useTranslation();
  const initialCategoryOptions = useCategoryOptions();
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>(
    initialCategoryOptions,
  );

  const updateAvailableCategoryOptions = (_: any, values: MenuFormValues) => {
    const selectedCategories =
      values.categories?.map((category) => category.category?.key) ?? "";

    const newOptions = Object.values(MenuCategory)
      .filter(
        (item) =>
          !selectedCategories.includes(item.toLowerCase() as MenuCategory),
      )
      .map((item) => ({
        label: t(`MENU_CONFIGURATION.MENU_CATEGORY.${item}`),
        value: item.toLowerCase(),
      }));

    setCategoryOptions(newOptions);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      autoComplete="off"
      onValuesChange={updateAvailableCategoryOptions}
    >
      <Form.List name="categories">
        {(fields, { add, remove }) => (
          <CategoryItem
            {...{
              fields,
              add,
              remove,
              form,
              categoryOptions,
              restaurantId,
              configuration,
            }}
          />
        )}
      </Form.List>

      <div className="my-5 flex justify-between">
        <Link to={AppRoutes.CHECKOUT_MENU.replace(":id", restaurantId)}>
          <Button type="default" className="m-5 mb-0">
            Podgląd menu
          </Button>
        </Link>
        <Button
          type="primary"
          htmlType="submit"
          className="bg-blue-950 m-5 mb-0"
        >
          Zapisz menu
        </Button>
      </div>
    </Form>
  );
};
