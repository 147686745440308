import { Button, Form, Input, Modal } from "antd";
import useMessage from "antd/es/message/useMessage";
import { ConfirmationResult, RecaptchaVerifier, getAuth } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useAuth } from "./AuthContext";
import "./PhoneInputStyles.css";

interface Props {
  showDialog: boolean;
}

export const AuthModal: React.FC<Props> = ({ showDialog }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = useMessage();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmation, setConfirmation] = useState<ConfirmationResult | null>(
    null,
  );

  const { signInWithPhone } = useAuth();
  const auth = getAuth();
  const recaptchaRef = useRef<RecaptchaVerifier | null>(null);

  useEffect(() => {
    recaptchaRef.current = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });
    return () => {
      recaptchaRef.current?.clear();
    };
  }, [auth]);

  const handleSubmitPhone = async () => {
    if (!recaptchaRef.current) {
      messageApi.error("Nie można zainicjować weryfikatora reCAPTCHA.");
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Wysyłanie...",
      duration: 0,
    });

    try {
      const conf = await signInWithPhone(phoneNumber, recaptchaRef.current);
      setConfirmation(conf);
      messageApi.destroy();
    } catch (error: unknown) {
      const e = error as Error;
      messageApi.destroy();
      if (e.message === "Firebase: Error (auth/too-many-requests).") {
        messageApi.error("Zbyt wiele prób, spróbuj później.");
      } else if (
        e.message === "Firebase: TOO_SHORT (auth/invalid-phone-number)."
      ) {
        messageApi.error("Nieprawidłowy numer telefonu.");
      } else {
        messageApi.error("Wystąpił błąd. Spróbuj ponownie później.");
      }
      console.error(e.message);
    }
  };

  const handleSubmitCode = async () => {
    if (!confirmation) {
      messageApi.error("Nie można zainicjować weryfikatora reCAPTCHA.");
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Sprawdzanie...",
      duration: 0,
    });

    try {
      await confirmation.confirm(verificationCode);
      messageApi.destroy();
    } catch (error) {
      messageApi.destroy();
      messageApi.error("Nie udało się zweryfikować kodu");
      console.error("Error verifying code:", error);
    }
  };

  const handleSubmit = () => {
    if (confirmation) {
      handleSubmitCode();
    } else {
      handleSubmitPhone();
    }
  };

  return (
    <>
      <Modal
        title="Logowanie"
        open={showDialog}
        closable={false}
        footer={[
          <Button key="submit" onClick={handleSubmit}>
            {confirmation ? "Zaloguj się" : "Wyślij kod weryfikacyjny"}
          </Button>,
        ]}
      >
        <Form form={form}>
          {!confirmation ? (
            <Form.Item
              label={
                <span className="text-lg font-semibold">Numer telefonu</span>
              }
              name="phoneNumber"
              rules={[
                { required: true, message: "Proszę podać numer telefonu!" },
              ]}
            >
              {
                <PhoneInput
                  international
                  placeholder="Podaj numer telefonu"
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value || "")}
                  defaultCountry="PL"
                  className="PhoneInput"
                />
              }
            </Form.Item>
          ) : (
            <Form.Item
              label="Kod weryfikacyjny"
              name="verificationCode"
              rules={[
                { required: true, message: "Proszę podać kod weryfikacyjny!" },
              ]}
            >
              <Input onChange={(e) => setVerificationCode(e.target.value)} />
            </Form.Item>
          )}
        </Form>
        {contextHolder}
      </Modal>
      <div id="recaptcha-container"></div>
    </>
  );
};
