import { useParams } from "react-router-dom";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Loadingbar } from "../../../components/Loadingbar";
import { StaffMenuConfiguration } from "../../Staff/Console/Menu/StaffMenuConfiguration";
import { Configuration } from "../../models/main.model";
import { useGetRestaurantById } from "../utils/getRestaurantById.hook";

export const StaffMenuConfigurationWrapper: React.FC<{
  configuration: Configuration | undefined;
}> = ({ configuration }) => {
  const { id } = useParams<{ id: string }>();
  const { restaurant, loading } = useGetRestaurantById({ id });

  if (loading || !restaurant) {
    return <Loadingbar />;
  }

  if (!id || !configuration)
    return (
      <ErrorMessage message="Something went wrong, restaurant not found :(" />
    );
  return (
    <StaffMenuConfiguration
      restaurant={restaurant}
      configuration={configuration}
    />
  );
};
