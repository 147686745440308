import { FirestoreError, collection, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../../../../firebase/firebase";
import { DB } from "../../../../models/firebase.models";
import { MenuCategory } from "../../../../models/main.model";

export function useGetCategoriesOnce({ restaurant }: { restaurant: string }) {
  const [categoriesError, setError] = useState<FirestoreError | undefined>();
  const [loadingCategories, setLoading] = useState(true);
  const [menuCategories, setMenuCategories] = useState<
    MenuCategory[] | undefined
  >([]);

  useEffect(() => {
    if (!restaurant) {
      setError({
        name: "AppError",
        code: "invalid-argument",
        message: "No restaurant specified",
      });
      return;
    }

    const fetchData = async () => {
      const docRef = doc(collection(firestore, restaurant), DB.MENU_CATEGORIES);
      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setMenuCategories(data?.categories as MenuCategory[]);
          setLoading(false);
        } else {
          setError({
            name: "FirebaseError",
            code: "invalid-argument",
            message: "no menu categories found",
            stack: "",
          });
        }
      } catch (e) {
        console.error("Error fetching categories:", e);
        setError(e as FirestoreError);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [restaurant]);

  return { menuCategories, loadingCategories, categoriesError };
}
