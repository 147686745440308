import { Layout } from "antd";
import React, { ReactNode } from "react";
import { FixedMenu } from "./components/FixedMenu";
import { FooterContent } from "./components/FooterContent";

const { Header, Content, Footer } = Layout;

interface Props {
  isConsole?: boolean;
  children: ReactNode;
}

export const StaffApp: React.FC<Props> = ({ isConsole = false, children }) => {
  return (
    <Layout style={{ minHeight: "100vh", padding: "0px" }}>
      <Header style={{ padding: 0 }}>
        <FixedMenu isConsole={isConsole} />
      </Header>
      <Content>{children}</Content>
      <Footer>
        <FooterContent />
      </Footer>
    </Layout>
  );
};
