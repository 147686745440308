import {
  BellOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../Routing/routing.model";
import { useAuth } from "../Auth/AuthContext";

interface Props {
  isConsole: boolean;
}

export const FixedMenu: React.FC<Props> = ({ isConsole }) => {
  const [current, setCurrent] = useState("help");
  const { signOut } = useAuth();

  const items: MenuProps["items"] = [
    {
      label: "Konto",
      key: "account",
      icon: <UserOutlined />,
      children: [
        // {
        //   label: `konto: ${currentUser ? currentUser.uid : "Niezalogowany"}`,
        //   key: "uid",
        // },
        {
          label: "Powiadomienia",
          key: "notifications",
          icon: <BellOutlined />,
        },
        {
          label: "Wyloguj",
          key: "signOut",
          icon: <PoweroffOutlined />,
        },
      ],
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    if (e.key === "signOut") {
      signOut();
    }
    setCurrent(e.key);
  };

  return (
    <div className="flex fixed w-full z-10 justify-between items-center pr-16 bg-white shadow-md">
      {isConsole ? (
        <Link to={AppRoutes.STAFF_HOME} className="flex items-center">
          <img src="/logo512.png" alt="Logo" className="h-12 w-auto ml-2" />
        </Link>
      ) : null}

      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        className="flex justify-end"
      />
    </div>
  );
};
