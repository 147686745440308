export const getCollectionNameForLanguage = (lang: string) => {
  switch (lang) {
    case "pl":
      return "menuPL";
    case "en":
      return "menuEN";
    case "ua":
      return "menuUA";
    default:
      return "menuPL";
  }
};
