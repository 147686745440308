import {
  MenuCategory,
  MenuMap,
  SelectOption,
} from "../../../../models/main.model";
import {
  CategoryForm,
  LocalizedMenuItem,
  MenuFormValues,
} from "../../../../models/menuConfiguration.models";

export const transformMenuMapToInitialValues = (
  menuMap: MenuMap,
  categoryOptions: SelectOption[],
): MenuFormValues => {
  const categories: CategoryForm[] = [];

  Object.keys(menuMap).forEach((categoryId) => {
    let maxItems = 0;
    const localizedItems: LocalizedMenuItem[] = [];

    Object.keys(menuMap[categoryId]).forEach((language) => {
      const numItems = menuMap[categoryId][language]?.length || 0;
      if (numItems > maxItems) {
        maxItems = numItems;
      }
    });

    Object.keys(menuMap[categoryId]).forEach((language) => {
      menuMap[categoryId][language]?.forEach((menuItem, index) => {
        const localizedNameKey =
          `name${language.toUpperCase()}` as keyof LocalizedMenuItem;
        const localizedDescriptionKey =
          `description${language.toUpperCase()}` as keyof LocalizedMenuItem;

        const updatedItem: LocalizedMenuItem = {
          ...localizedItems[index],
          [localizedNameKey]: menuItem.name,
          [localizedDescriptionKey]: menuItem.description,
          price: menuItem.price,
          image: menuItem.image,
          id: index.toString(),
        };

        localizedItems[index] = updatedItem;
      });
    });

    const matchingOption = categoryOptions.find(
      (option) => option.value === categoryId.toLowerCase(),
    );
    const label = matchingOption ? matchingOption.label : "";

    categories.push({
      category: {
        key: categoryId as MenuCategory,
        label: label,
      },
      details: localizedItems,
    });
  });

  return {
    categories,
  };
};
