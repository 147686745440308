import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";

export function useGetLogo(id: string | null): {
  logoUrl: string | undefined;
  Logoloading: boolean;
} {
  const [logoUrl, setLogoURL] = useState<string>();
  const [Logoloading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      console.log("No restaurant id provided. Cannot fetch logo.");
      return;
    }

    setLoading(true);
    const storage = getStorage();
    const logoRef = ref(storage, `${id}/logo.png`);

    getDownloadURL(logoRef)
      .then((url) => {
        setLogoURL(url);
      })
      .catch((error) => {
        setLogoURL(
          "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png",
        );
        console.error("Error occurred while fetching the logo URL: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return { logoUrl, Logoloading };
}
