import { useLocation } from "react-router-dom";
import { AppRoutes } from "../routing.model";

const matchPattern = (
  pathname: string,
  pattern: string,
): Record<string, string> | null => {
  const keys: string[] = [];
  const patternToRegExp = new RegExp(
    "^" +
      pattern.replace(/:([a-zA-Z]+)/g, (_, key) => {
        keys.push(key);
        return "([^/]+)";
      }) +
      "$",
  );

  const match = pathname.match(patternToRegExp);

  if (!match) return null;

  const params: Record<string, string> = {};

  for (let i = 1; i < match.length; i++) {
    const key = keys[i - 1];
    const value = match[i];
    params[key] = value;
  }

  return params;
};

export const useRestaurantId = (): string | null => {
  const location = useLocation();
  const pathsToCheck = Object.values(AppRoutes);

  for (const path of pathsToCheck) {
    const params = matchPattern(location.pathname, path);
    if (params && params.id) {
      return params.id;
    }
  }

  return null;
};
