import { Spin } from "antd";

export const Loadingbar = () => {
  return (
    <div className="flex items-center justify-center h-screen text-2xl">
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-200 bg-opacity-50">
        <div className="p-6 bg-white rounded shadow-lg text-2xl text-gray-800">
          <Spin size="large"></Spin>
        </div>
      </div>
    </div>
  );
};
