import { getDownloadURL, getStorage, ref } from "firebase/storage";

export async function fetchInitialImageUrlsFromFirebase(
  restaurantId: string,
  currentImageUrls: Record<string, string>,
): Promise<Record<string, string>> {
  const storage = getStorage();
  let updatedImageUrls: Record<string, string> = {};

  for (const imageName of Object.keys(currentImageUrls)) {
    const imageRef = ref(storage, `${restaurantId}/menu/${imageName}`);

    try {
      const url = await getDownloadURL(imageRef);
      updatedImageUrls[imageName] = url;
    } catch (error) {
      console.error(`Failed to fetch image for ${imageName}: `, error);
    }
  }

  return updatedImageUrls;
}
