import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Menu, MenuProps } from "antd";
import React, { useState } from "react";
import { AppRoutes } from "../../Routing/routing.model";
import { StaffApp } from "../StaffApp";
import { items } from "./ConsoleMenuItems";

interface Props {
  children: React.ReactNode;
}

export const StaffConsoleApp: React.FC<Props> = ({ children }) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const onClick: MenuProps["onClick"] = (e) => {
    console.log(e);
    // TODO: Implement navigation to the selected route once the router is integrated.
    // Navigate(e.key);
  };

  return (
    <StaffApp isConsole={true}>
      <div className="flex flex-row">
        <div
          className={`h-screen z-0 ${menuCollapsed ? "w-20" : "w-52"}`}
        ></div>
        <div
          className={`h-screen fixed z-50 ${menuCollapsed ? "w-20" : "w-52"}`}
        >
          <Menu
            theme="dark"
            onClick={onClick}
            defaultSelectedKeys={[AppRoutes.STAFF_CONSOLE_MENU]}
            mode="inline"
            items={items}
            className="max-w-[208px] h-screen"
            inlineCollapsed={menuCollapsed}
          />
          <Button
            onClick={toggleCollapsed}
            className={`fixed bottom-3 text-2xl h-fit text-white ${
              menuCollapsed ? "left-3" : "left-36"
            }`}
          >
            <div>{menuCollapsed ? <RightOutlined /> : <LeftOutlined />}</div>
          </Button>
        </div>
        <div
          className={`pl-${
            menuCollapsed ? "24" : "56"
          } transition-all ease-in-out duration-250 flex-1`}
        >
          {children}
        </div>
      </div>
    </StaffApp>
  );
};
