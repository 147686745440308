import { Card, Form, Spin, message } from "antd";
import { useEffect } from "react";
import { ErrorMessage } from "../../../../components/ErrorMessage";
import { RestaurantBasicInfo } from "../../../models/firebase.models";
import { Configuration, MenuCategory } from "../../../models/main.model";
import { MenuFormValues } from "../../../models/menuConfiguration.models";
import { StaffConsoleApp } from "../StaffConsoleApp";
import { MenuForm } from "./MenuForm/MenuForm";
import { transformMenuMapToInitialValues } from "./utils/transformMenuMapToInitialValues";
import { uploadFormDataToFirebase } from "./utils/uploadFormDataToFirebase";
import { useCategoryOptions } from "./utils/useCategoryOptions.hook";
import { useGetAllMenus } from "./utils/useGetAllMenus";
import { useGetCategoriesOnce } from "./utils/useGetCategoriesOnce.hook";

interface Props {
  restaurant: RestaurantBasicInfo;
  configuration: Configuration;
}

export const StaffMenuConfiguration: React.FC<Props> = ({
  restaurant,
  configuration,
}) => {
  const [form] = Form.useForm<MenuFormValues>();

  const { menuCategories } = useGetCategoriesOnce({
    restaurant: restaurant.id,
  });
  const { allMenus, loading, error } = useGetAllMenus(
    restaurant.id,
    menuCategories as MenuCategory[] | undefined,
    configuration,
  );
  const categoryOptions = useCategoryOptions();

  useEffect(() => {
    const initialValues = transformMenuMapToInitialValues(
      allMenus,
      categoryOptions,
    );
    form.setFieldsValue(initialValues);
  }, [allMenus, categoryOptions, form]);

  if (loading || !menuCategories || !allMenus) {
    return (
      <StaffConsoleApp>
        <Spin className="fixed top-1/2 left-1/2" size="large" />
      </StaffConsoleApp>
    );
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const handleSubmit = (values: MenuFormValues) => {
    if (restaurant.id === "twoja-restauracja") {
      message.warning("Tej restauracji nie można edytować");
      return;
    }

    uploadFormDataToFirebase(restaurant.id, values, configuration)
      .then(() => {
        message.success("Menu zostało zaktualizowane");
      })
      .catch((error) => {
        message.error(`Błąd podczas aktualizacji menu: ${error.message}`);
      });
  };

  return (
    <StaffConsoleApp>
      <div className="mx-8 mt-4">
        <Card>
          <div className="text-3xl">Dodaj Menu</div>
          <div className="opacity-30 text-xs mb-3">
            Języki: {configuration.languages.map((l) => l).join(", ")}
          </div>
          <MenuForm
            form={form}
            handleSubmit={handleSubmit}
            restaurantId={restaurant.id}
            configuration={configuration}
          />
        </Card>
      </div>
    </StaffConsoleApp>
  );
};
