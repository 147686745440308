import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

type UploadCallback = (imageUrl: string, imageName: string) => void;

export const uploadImageToFirebase = async (
  file: File,
  restaurantId: string,
  callback: UploadCallback,
) => {
  const storage = getStorage();
  const storageRef = ref(storage, `${restaurantId}/menu/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  // TODO: make progress visible to user

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
    },
    (error) => {
      console.error("Upload failed:", error);
    },
    async () => {
      try {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        callback(downloadURL, file.name);
      } catch (error) {
        console.error("Failed to get download URL:", error);
      }
    },
  );
};
