import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase/firebase";
import { DB } from "../../../../models/firebase.models";
import { Configuration } from "../../../../models/main.model";
import { MenuFormValues } from "../../../../models/menuConfiguration.models";
import { transformInitialValuesToMenuMap } from "./transformInitialValuesToMenuMap";

export const uploadFormDataToFirebase = async (
  restaurantId: string,
  formValues: MenuFormValues,
  configuration: Configuration,
): Promise<void> => {
  const transformedData = transformInitialValuesToMenuMap(formValues);

  const menuCategoriesDocRef = doc(
    firestore,
    `${restaurantId}/${DB.MENU_CATEGORIES}`,
  );

  const languages = configuration.languages.map((language) =>
    language.toUpperCase(),
  );

  try {
    await setDoc(
      menuCategoriesDocRef,
      { categories: Object.keys(transformedData) },
      { merge: true },
    );

    for (const lang of languages) {
      for (const [category, items] of Object.entries(transformedData)) {
        const categoryCollectionRef = collection(
          firestore,
          `${restaurantId}/menu${lang}/${category}`,
        );

        // If the category is empty, we add an empty document to force the collection to be created
        if (items[lang]?.length === 0) {
          await addDoc(categoryCollectionRef, {});
        }

        for (const item of items[lang] || []) {
          item.id = item.id.toString();

          // We create a reference to the document with a specific ID
          const itemDocRef = doc(categoryCollectionRef, item.id);

          // Filter objects before saving by removing empty or undefined values
          const cleanedItem = Object.fromEntries(
            Object.entries(item).filter(([_, value]) => value !== undefined),
          );
          await setDoc(itemDocRef, cleanedItem);
        }
      }
    }
  } catch (error) {
    console.error("An error occurred while updating menu data.", error);
  }
};
