export enum AppRoutes {
  LANDING_PAGE = "/",

  CHECKOUT_BASE = "/checkout/:id",
  CHECKOUT_HOME = "/checkout/:id/:table/home",
  CHECKOUT_MENU = "/checkout/:id/menu",

  STAFF_HOME = "/staff/home",
  STAFF_BASE = "/staff/:id/",
  STAFF_CONSOLE_BASE = "/staff/:id/console/",
  STAFF_CONSOLE_MENU = "/staff/:id/console/menu/",
}
