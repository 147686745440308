export function isValid<T>(data: any): data is T {
  const configurationFields: { [key: string]: string } = {
    name: "string",
    backgroundColor: "string",
    mainColor: "string",
    textColor: "string",
  };

  for (const field in configurationFields) {
    if (!(field in data) || typeof data[field] !== configurationFields[field]) {
      return false;
    }
  }
  return true;
}
