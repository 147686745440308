import { Language } from "../../../../models/i18n.model";
import { MenuMap } from "../../../../models/main.model";
import {
  CategoryForm,
  LocalizedMenuItem,
  MenuFormValues,
} from "../../../../models/menuConfiguration.models";

export const transformInitialValuesToMenuMap = (
  menuFormValues: MenuFormValues,
): MenuMap => {
  const menuMap: MenuMap = {};

  menuFormValues.categories.forEach((categoryForm: CategoryForm) => {
    const categoryId = categoryForm.category;
    menuMap[categoryId.key] = {};

    categoryForm.details.forEach((localizedItem: LocalizedMenuItem) => {
      Object.keys(Language).forEach((language) => {
        const localizedNameKey = `name${language}`;
        const localizedDescriptionKey = `description${language}`;

        if (localizedItem[localizedNameKey as keyof LocalizedMenuItem]) {
          if (!menuMap[categoryId.key][language]) {
            menuMap[categoryId.key][language] = [];
          }

          menuMap[categoryId.key][language]?.push({
            id: localizedItem.id,
            name: localizedItem[
              localizedNameKey as keyof LocalizedMenuItem
            ] as string,
            description: localizedItem[
              localizedDescriptionKey as keyof LocalizedMenuItem
            ] as string,
            price: localizedItem.price!,
            image: localizedItem.image,
          });
        }
      });
    });
  });

  return menuMap;
};
