import { FirestoreError, collection, getDocs } from "@firebase/firestore";

import { getCollectionNameForLanguage } from "../../../../Checkout/Menu/utils/getCollectionNameForLanguage";
import { firestore } from "../../../../firebase/firebase";
import { Language } from "../../../../models/i18n.model";
import { MenuCategory, MenuItem } from "../../../../models/main.model";

export const fetchMenuForCategory = async (
  restaurant: string,
  category: MenuCategory | null,
  language: Language,
): Promise<{
  menu: MenuItem[] | undefined;
  error: FirestoreError | undefined;
}> => {
  if (!category || !restaurant) {
    throw new Error("Invalid arguments");
  }

  const collectionName = getCollectionNameForLanguage(language);

  const menuItemsRef = collection(
    firestore,
    restaurant,
    collectionName,
    category,
  );
  try {
    const snapshot = await getDocs(menuItemsRef);
    const menuData: MenuItem[] = [];

    snapshot.forEach((doc) => {
      const item = doc.data() as MenuItem;
      menuData.push(item);
    });

    if (menuData.length === 0) {
      const error: FirestoreError = {
        name: "FirebaseError",
        code: "invalid-argument",
        message: "Menu not found :(",
        stack: "",
      };
      return { menu: undefined, error };
    }

    return { menu: menuData, error: undefined };
  } catch (e) {
    console.log("Error", e);
    return { menu: undefined, error: e as FirestoreError };
  }
};
