import {
  AppstoreOutlined,
  BarChartOutlined,
  CompassOutlined,
  EditOutlined,
  QrcodeOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import React from "react";
import { AppRoutes } from "../../Routing/routing.model";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const items: MenuProps["items"] = [
  getItem("Menu", AppRoutes.STAFF_CONSOLE_MENU, <UnorderedListOutlined />),
  getItem("Stoliki", "1", <AppstoreOutlined />),
  getItem("QR kody", "2", <QrcodeOutlined />),
  getItem("Konfiguracja", "3", <EditOutlined />),
  getItem("Pracownicy", "4", <TeamOutlined />),
  getItem("Raporty", "5", <BarChartOutlined />),
  getItem("Tryb", "6", <CompassOutlined />, [
    getItem("Kelner", "7"),
    getItem("Kucharz", "8"),
    getItem("Kierowca", "9"),
  ]),
];
