import {
  FirestoreError,
  collection,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Configuration } from "../../models/main.model";
import { firestore } from "../firebase";
import { isValid } from "../utils/isValid";

export function useGetConfiguration({
  restaurant,
}: {
  restaurant: string | null;
}): {
  configuration: Configuration | undefined;
  configurationLoading: boolean;
  error: FirestoreError | undefined;
} {
  const [error, setError] = useState<FirestoreError | undefined>();
  const [configurationLoading, setConfigurationLoading] = useState(true);
  const [configuration, setConfiguration] = useState<
    Configuration | undefined
  >();

  useEffect(() => {
    if (!restaurant) {
      console.log("No restaurant id provided.");
      return;
    }

    setConfigurationLoading(true);
    const unsubscribe = onSnapshot(
      doc(collection(firestore, restaurant), "configuration"),
      (snapshot) => {
        const data = snapshot.data();
        if (!data) {
          setError({
            name: "FirebaseError",
            code: "invalid-argument",
            message: "Restaurant not found - bad request",
            stack: "",
          });
          setConfigurationLoading(false);
          return;
        }
        if (!isValid<Configuration>(data)) {
          setError({
            name: "FirebaseError",
            code: "invalid-argument",
            message: "Invalid configuration data - restaurant error",
            stack: "",
          });
          setConfigurationLoading(false);
          return;
        }
        setConfiguration(data as Configuration);
        setConfigurationLoading(false);
      },
      (e) => {
        console.log("Error", e);
        setError(e);
        setConfigurationLoading(false);
      },
    );
    return () => unsubscribe();
  }, [restaurant]);

  return { configuration, configurationLoading, error };
}
