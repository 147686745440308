import { useEffect, useState } from "react";
import { Language } from "../../../../models/i18n.model";
import {
  Configuration,
  MenuCategory,
  MenuMap,
} from "../../../../models/main.model";
import { fetchMenuForCategory } from "./fetchMenuForCategory";

export const useGetAllMenus = (
  restaurantId: string,
  categories: MenuCategory[] | undefined,
  configuration: Configuration,
) => {
  const [allMenus, setAllMenus] = useState<MenuMap>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (!categories) {
      return; // TODO: add different state
    }

    setLoading(true);

    const promises = categories.flatMap((category) =>
      configuration.languages.map((language) =>
        fetchMenuForCategory(restaurantId, category, language as Language).then(
          (menuItems) => ({
            categoryId: category,
            language,
            menuItems,
          }),
        ),
      ),
    );

    Promise.all(promises)
      .then((results) => {
        const menuMap: MenuMap = {};

        results.forEach(({ categoryId, language, menuItems }) => {
          if (!menuItems || !menuItems.menu) {
            // TODO: Here you can set some default state or log an error
            return;
          }

          if (!menuMap[categoryId]) {
            menuMap[categoryId] = {};
          }

          menuMap[categoryId][language] = menuItems.menu.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
            price: item.price,
            image: item.image,
          }));
        });

        setAllMenus(menuMap);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [restaurantId, categories, configuration.languages]);

  return { allMenus, loading, error };
};
