import { I18nextProvider } from "react-i18next";
import LanguageSwitcher from "./components/LanguageSwitcher/LanguageSwitcher";
import { Routing } from "./features/Routing/Routing";
import { useGetConfiguration } from "./features/firebase/hooks/getConfiguration.hook";
import i18n from "./i18n/i18n";

import { ErrorMessage } from "./components/ErrorMessage";
import { Loadingbar } from "./components/Loadingbar";
import { useRestaurantId } from "./features/Routing/utils/useRestaurantId.hook";
import { useGetLogo } from "./features/firebase/hooks/getLogo.hook";
import { AuthProvider } from "./features/Staff/Auth/AuthContext";

function App() {
  const id = useRestaurantId();
  const { logoUrl, Logoloading } = useGetLogo(id);
  const { configuration, configurationLoading, error } = useGetConfiguration({
    restaurant: id,
  });

  if (id) {
    if (configurationLoading || Logoloading) {
      return <Loadingbar />;
    } else if (!configuration && !configurationLoading) {
      return <ErrorMessage message={"Configuration not found"} />;
    }
    if (error) {
      return <ErrorMessage message={error.message} />;
    }
  }

  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <LanguageSwitcher
          languages={
            configuration ? configuration.languages : ["pl", "en", "ua"]
          }
        />
        <Routing configuration={configuration} logoUrl={logoUrl} />
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;
