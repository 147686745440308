import { UserRole } from "./user.models";

export enum DB {
  // general
  APP = "app",
  DATA = "data",

  // users
  USERS = "users",
  NOTIFICATIONS = "notifications",

  // restaurant
  RESTAURANTS = "restaurants",
  CONFIGURATION = "configuration",
  MENU_CATEGORIES = "menuCategories",
}

export enum NOTIFICATION_STATUS {
  NEW = "new",
  OLD = "old",
}

export interface Notification {
  title: string;
  message: string;
  time: Date;
  status: NOTIFICATION_STATUS;
}

export interface RestaurantBasicInfo {
  id: string;
  name: string;
  role: UserRole;
}

export enum FirebaseCallableFunctions {
  ON_CONTACT_FORM_SIGN = "onContactFormSign",
}
