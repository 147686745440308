import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MenuCategory, SelectOption } from "../../../../models/main.model";

export function useCategoryOptions(): SelectOption[] {
  const { t } = useTranslation();

  return useMemo(() => {
    return Object.values(MenuCategory).map((item) => ({
      label: t(`MENU_CONFIGURATION.MENU_CATEGORY.${item}`),
      value: item.toLowerCase(),
    }));
  }, [t]);
}
